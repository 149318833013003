<!-- components/AppFooter.vue -->
<!-- components/AppFooter.vue -->
<script lang="ts" setup>
import companyProfile from "@/assets/files/Company_Profile.pdf";
import AppInput from "./AppInput.vue";

const { locale } = useI18n();
const feedbackModal = ref(false);
const config = useRuntimeConfig();
const errorMessage = ref("");
const code = ref("");
function downloadPdf(pdfUrl: string, fileName: string) {
  const link = document.createElement("a");
  link.href = pdfUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const submitForm = async () => {
  try {
    if (code.value === `${config.public.showroomStockCode}`) {
      window.location.href =
        "https://docs.google.com/forms/d/e/1FAIpQLSf3tT-vK0bT-mkAvlxGtSiK7MLfKl57JBJN1sh3HAudupUhtA/viewform";
    } else {
      errorMessage.value = "Invalid code. Please try again.";
    }
  } catch (error) {
    errorMessage.value =
      "An unexpected error occurred. Please try again later.";
  }
};
// Ensure computed property reacts to locale changes
const isRTL = computed(() => {
  return locale.value === "ar";
});

// Conditional button style based on RTL/LTR
const buttonStyle = computed(() => {
  return isRTL.value
    ? { left: "15px", right: "auto" }
    : { right: "15px", left: "auto" };
});
</script>

<template>
  <footer>
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-3 col-6">
              <div class="fs-20 fw-700 mb-3">
                {{ $t("about_cartrust") }}
              </div>
              <ul>
                <li class="mb-2">
                  <NuxtLinkLocale class="fs-16 fw-500 text-black" to="/about">
                    {{ $t("about_us") }}
                  </NuxtLinkLocale>
                </li>
                <li class="mb-2">
                  <a href="/" class="fs-16 fw-500 text-black">
                    {{ $t("our_story") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a href="/partners" class="fs-16 fw-500 text-black">
                    {{ $t("our_partners") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a href="/our-clients" class="fs-16 fw-500 text-black">
                    {{ $t("our_clients") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a href="/cartrust-agent" class="fs-16 fw-500 text-black">
                    {{ $t("cartrust_marketer_program") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a
                    href="/data-products-disclaimer"
                    class="fs-16 fw-500 text-black"
                  >
                    {{ $t("cartrust_data_products_disclaimer") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6">
              <div class="fs-20 fw-700 mb-3">&nbsp;</div>
              <ul>
                <li class="mb-2">
                  <a href="/" class="fs-16 fw-500 text-black">
                    {{ $t("investors") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a
                    href="/impartiality-policy"
                    class="fs-16 fw-500 text-black"
                  >
                    {{ $t("impartiallity_policy") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a href="/privacy-policy" class="fs-16 fw-500 text-black">
                    {{ $t("privacy_policy") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a
                    href="/terms-and-condition"
                    class="fs-16 fw-500 text-black"
                  >
                    {{ $t("terms_and_conditions") }}
                  </a>
                </li>
                <li class="mb-2">
                  <a href="/branches" class="fs-16 fw-500 text-black"
                    >{{ $t("service_centers") }}
                  </a>
                </li>
                <li @click="feedbackModal = true" class="mb-2 cursor-pointer">
                  {{ $t("exhibition_stock") }}
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6 mb-md-0 mb-4">
              <div class="fs-20 fw-700 mb-3">&nbsp;</div>
              <ul>
                <li class="mb-2">
                  <span
                    @click="downloadPdf(companyProfile, 'Company Profile.pdf')"
                    class="fs-16 fw-500 text-black cursor-pointer"
                  >
                    {{ $t("company_profile") }}
                  </span>
                </li>
                <li class="mb-2">
                  <NuxtLinkLocale class="fs-16 fw-500 text-black" to="/contact">
                    {{ $t("contact_us") }}
                  </NuxtLinkLocale>
                </li>
                <li class="mb-2">
                  <a href="/career" class="fs-16 fw-500 text-black">
                    {{ $t("career") }}
                  </a>
                </li>
                <li class="mb-2">
                  <NuxtLinkLocale class="fs-16 fw-500 text-black" to="/faq">
                    {{ $t("faq") }}
                  </NuxtLinkLocale>
                </li>
                <li class="mb-2">
                  <NuxtLinkLocale
                    class="fs-16 fw-500 text-black"
                    to="/trailer-inspection-and-half-trailers"
                  >
                    {{ $t("trailer_and_semi_trailer_inspection") }}
                  </NuxtLinkLocale>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-6 mb-md-0 mb-4">
              <div class="fs-20 fw-700 mb-3">{{ $t("download_app") }}</div>
              <ul>
                <li class="mb-2">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/sa/app/cartrust/id1581827929"
                  >
                    <button type="button" class="bg-transparent border-0">
                      <img
                        src="assets/images/app-blue.svg"
                        height="41px"
                        loading="lazy"
                        alt="App Download link"
                      />
                    </button>
                  </a>
                </li>
                <li class="mb-4">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.cartrustcustomerappfix"
                  >
                    <button type="button" class="bg-transparent border-0">
                      <img
                        src="assets/images/google-blue.svg"
                        height="40px"
                        loading="lazy"
                        alt="Google Play store link"
                      />
                    </button>
                  </a>
                </li>
                <li class="mb-2">
                  <button type="button" class="bg-transparent border-0">
                    <img src="assets/images/number.svg" height="40px" loading="lazy" alt="Vat number" />
                  </button>
                </li>
                <li class="mb-2 electronic-number pe-1">
                  <span class="">{{ $t("e-commerce_document_number") }}</span>
                  <br />
                  <span class="num">0000003831</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-md-0 mb-4">
          <div class="fs-20 fw-700 mb-3 mb-2">
            {{ $t("subscribe_to_the_newsletter") }}
          </div>
          <div class="input-footer position-relative">
            <input type="text" :placeholder="$t('email')" />
            <button
              type="button"
              class="main-btn h-auto px-4 py-3"
              :style="buttonStyle"
            >
              <img
                v-if="locale === 'ar'"
                src="assets/images/angle-left.svg"
                loading="lazy"
                alt="news letter button"
              />
              <img v-else src="assets/images/angle-right.png" loading="lazy" alt="news letter button" />
            </button>
          </div>
          <div class="d-flex align-items-center gap-2 mb-2">
            <div class="d-flex align-items-center gap-2">
              <img src="assets/images/star.svg" loading="lazy" alt="star" />
              <img src="assets/images/star.svg" loading="lazy" alt="star" />
              <img src="assets/images/star.svg" loading="lazy" alt="star" />
              <img src="assets/images/star.svg" loading="lazy" alt="star" />
              <img src="assets/images/star.svg" loading="lazy" alt="star" />
            </div>
            <div class="fs-20 fw-700">5.0</div>
          </div>
          <div class="fs-16 fw-500 mb-2">
            {{ $t("customer_rated_number") }}
          </div>
          <div class="d-flex align-items-center mb-2 flex-wrap gap-3">
            <div class="fs-14 fw-500 text-grey">{{ $t("credits") }}</div>
            <img src="assets/images/foot-part1.png" loading="lazy" alt="credits logo 1" />
            <img src="assets/images/foot-part2.png" loading="lazy" alt="credits logo 2" />
            <img src="assets/images/saac.png" loading="lazy" alt="credits logo 3" />
          </div>
          <div class="d-flex align-items-center mb-2 flex-wrap gap-3">
            <img src="assets/images/foot-part3.png" loading="lazy" alt="mada" />
            <img src="assets/images/foot-part4.png" loading="lazy" alt="visa" />
            <img src="assets/images/foot-part5.png" loading="lazy" alt="stc pay" />
            <img src="assets/images/foot-part6.png" loading="lazy" alt="apple pay" />
            <img src="assets/images/foot-part7.png" loading="lazy" alt="tabby" />
            <img src="assets/images/foot-part8.png" loading="lazy" alt="tamara" />
          </div>
        </div>
      </div>
      <div class="sub-footer pt-3">
        <div
          class="d-flex align-items-center flex-wrap justify-content-md-between gap-md-0 gap-4 justify-content-center"
        >
          <img src="assets/images/logo.png" loading="lazy" alt="logo" />

          <div class="d-flex align-items-center gap-3">
            <div class="fs-16 fw-500 text-theme">
              {{ $t("social_media_accounts") }}
            </div>
            <div class="d-flex align-items-center gap-2">
              <a target="_blank" href="https://www.youtube.com/@cartrust5834"
                ><img src="assets/images/youtube.svg" loading="lazy" alt="youtube"
              /></a>
              <a target="_blank" href="https://www.tiktok.com/@cartrustksa"
                ><img src="assets/images/tiktok.svg" loading="lazy" alt="tiktok"
              /></a>
              <a target="_blank" href="https://twitter.com/CarTrustksa"
                ><img src="assets/images/twitter.svg" loading="lazy" alt="twitter"
              /></a>
              <a target="_blank" href="https://www.instagram.com/cartrustksa"
                ><img src="assets/images/instagram.svg" loading="lazy" alt="instagram"
              /></a>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61556080312974"
                ><img src="assets/images/facebook.svg" loading="lazy" alt="facebook"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3">
        <div class="d-flex align-items-center flex-wrap justify-content-center">
          <div class="d-flex align-items-center gap-3 order-lg-1 order-2">
            <div class="d-flex align-items-center gap-2">
              <div class="fs-16 fw-500 text-theme">
                {{ $t("copyright_text") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <AppModal
    :visible="feedbackModal"
    @update:visible="(v:any) => (feedbackModal = v)"
    size="lg"
    ref="carouselRef"
  >
    <template #default="{ close }">
      <div :dir="isRTL ? 'rtl' : 'ltr'" class="p-4">
        <form @submit.prevent="submitForm">
          <AppInput
            v-model="code"
            :label="$t('enter_the_access_code')"
            must="true"
            type="text"
            :placeholder="$t('enter_the_access_code') "
            name="Code"
          />
          <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
          <button
            class="bg-primary text-white fw-600 px-4 py-1 mt-3 border-0 rounded"
          >
            {{ $t('send') }}
          </button>
        </form>
      </div>
    </template>
  </AppModal>
</template>

<style lang="css" scoped>
footer {
  background: #f6f6f6;
  padding: 70px 0;
  margin-top: 50px;
}
footer {
  padding: 40px 0;
}

.input-footer input {
  background: #e9e9e9bf;
  border: none;
  outline: none;
  border-radius: 15px;
  width: 100%;
  height: 64px;
  padding: 0 15px;
}
.input-footer .main-btn {
  position: absolute;
  top: 8px;
}

.electronic-number span {
  font-family: "Tajawal", Arial, sans-serif;
  color: black;
  font-weight: 500;
}
</style>
